<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%">
					<a-row style="margin-bottom: 20px" type="flex" justify="space-between">
						<a-col span="10">
							<a-button-group>
								<a-button type="primary" @click="$refs.profileForm._show(fileId, '文件夹')">添加文件夹</a-button>
								<a-button type="primary" @click="$refs.profileForm._show(fileId, '文件')">添加文件</a-button>
							</a-button-group>
						</a-col>
						<a-col span="12">
							<a-row type="flex" justify="end" :gutter="3">
								<a-col>
									<a-input v-model="param.keyword" allowClear search @pressEnter="getfilesList()" @on-clear="getfilesList()" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col v-if="fileId">
									<a-button @click="black">上一级</a-button>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<div style="font-weight: 600">{{ `文件路径：${path}` }}</div>
						</a-col>
					</a-row>
					<hr />
				</a-affix>
			</div>
		</a-layout>

		<div>
			<a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="fileId" border ref="list" :loading="loading" :data-source="data.records" :customRow="customRow">
				<!-- 文件类型 -->
				<template slot-scope="row" slot="type">
					<a-button type="link">
						<a-icon v-if="row.type == '目录'" type="folder" theme="filled" />
						<a-icon v-if="row.type == '文件'" type="file" theme="filled" />
					</a-button>
				</template>
				<!-- 大小 -->
				<template slot-scope="row" slot="size">
					<div v-if="row.size < 1000">{{ row.size }}kb</div>
					<div v-if="row.size >= 1000">{{ row.size / 1000 }}mb</div>
				</template>
				<!--操作-->
				<template slot-scope="row" slot="action">
					<a-button-group shape="circle">
						<a-tooltip v-if="row.type == '文件' && utils.isWordOrExcelFile(row.name)" title="预览">
							<a-button @click="$refs.office.show(row.url, 0)" type="link" icon="eye"></a-button>
						</a-tooltip>
						<a-tooltip v-if="row.type == '文件' && utils.isImageFile(row.name)" title="预览">
							<a-button @click="utils.openImage(row.url)" type="link" icon="eye"></a-button>
						</a-tooltip>
						<a-tooltip v-if="row.type == '文件' && utils.isWordOrExcelFile(row.name)" title="编辑">
							<a-button @click="$refs.office.show(row.url, 1)" type="link" icon="edit"></a-button>
						</a-tooltip>
						<a-tooltip v-if="row.type == '文件'" title="下载">
							<a-button @click="down(row)" type="link" icon="download"></a-button>
						</a-tooltip>
						<a-tooltip title="删除">
							<a-button v-has="'space:profile:del'" type="link" icon="delete" @click="del(row.fileId)"></a-button>
						</a-tooltip>
					</a-button-group>
				</template>
			</a-table>
			<a-row>
				<a-col span="12" offset="6" style="text-align: center; padding: 20px 0">
					<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit" :current="data.current" />
				</a-col>
			</a-row>
		</div>
		<profile-Form ref="profileForm" @success="doRefresh"></profile-Form>
		<office ref="office"></office>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import profileForm from './components/profileForm.vue';
import office from '@/components/office/document';
export default {
	components: { profileForm, office },
	data() {
		return {
			data: {},
			loading: false,
			organList: apiUtil.getOrganList(),
			pageSizeOptions: ['10', '20', '30', '40', '50'],
			pageSize: 10,
			fileId: null,
			parentId: '',
			total: '',
			param: {
				current: 1,
				limit: 20,
				keyword: ''
			},
			path: '/',
			pathArr: [],
			Documentdata: [],
			columns: [
				{ title: '', width: 80, align: 'center', scopedSlots: { customRender: 'type' } },
				{ title: '文件名称', dataIndex: 'name' },
				{ title: '创建时间', dataIndex: 'createTime' },
				{ title: '大小', scopedSlots: { customRender: 'size' } },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
			]
		};
	},

	mounted() {
		this.getfilesList();
	},

	methods: {
		// 获取文件列表
		getfilesList(fileId = this.fileId) {
			this.loading = true;
			this.http
				.get('/platform/scope/profile', { fileId: fileId, ...this.param })
				.then(ret => {
					this.data = ret.data;
					this.total = data.total;
					this.param.current = data.current;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},

		// 下载
		down(row) {
			console.log(row);
			this.http
				.download(`/platform/scope/download/${row.code}`, {}, row.name)
				.then(ret => {
					console.log(ret);
				})
				.catch(err => {});
		},
		// 删除文件夹
		del(id) {
			const ids = [];
			ids.push(id);
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.post('/platform/scope/profile/del', { ids: ids }).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getfilesList();
						});
					}
				});
			});
		},

		// 返回上一级
		black() {
			const len = this.Documentdata.length;
			if (this.Documentdata.length < 1) {
				return;
			} else {
				let parId = this.Documentdata[len - 1].parentId;
				let index = this.pathArr.findIndex(item => item == this.Documentdata[len - 1].name);
				this.pathArr.splice(index, 1);
				this.path = this.pathArr.join(' > ');
				if (parId == 0) {
					this.fileId = null;
					this.getfilesList();
				} else {
					this.getfilesList(this.Documentdata[len - 1].parentId);
					this.Documentdata.pop(1);
					if (this.Documentdata.length == 0) {
						this.fileId = '';
					} else {
						const id = this.Documentdata[this.Documentdata.length - 1].fileId;
						console.log(id);
						this.fileId = id;
					}
				}
			}
		},
		//管理员权限设置所在单位
		getOrganId() {
			return apiUtil.getOrganId();
		},

		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getfilesList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getfilesList();
		},
		doRefresh() {
			// console.log('成功成功');
			this.getfilesList();
		},
		customRow(record, index) {
			if (record.type == '目录') {
				return {
					on: {
						dblclick: () => {
							this.param.keyword = '';
							this.Documentdata.push(record);
							// console.log(this.Documentdata);
							this.fileId = record.fileId;
							this.pathArr.push(record.name);
							this.path = this.pathArr.join(' > ');
							this.parentId = record.parentId;
							this.getfilesList();
						}
					}
				};
			}
		}
	}
};
</script>

<style></style>
